<template>
  <div class="page-content-webhook">
    <div class="header">
      <h4>Gestion des Webhooks</h4>

      <b-button size="sm" variant="success" v-b-modal.addWebhook>
        <font-awesome-icon class="" icon="plus" />
        Créer un nouveau webhook
      </b-button>
    </div>
    <div v-if="getWebhookLoading" class="three-dots-loading ml-3 mb-1 mt-2">
      Chargement en cours
    </div>
    <hr />
    <div class="webhook">
      <b-table-simple class="webhook-table" small hover>
        <b-thead>
          <b-tr class="header-tr">
            <b-th>Événements</b-th>
            <b-th>URL du point de destination</b-th>
            <b-th>Créé</b-th>
            <b-th>Dernière tentative</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            class="sub-header-tr"
            v-for="webhook in getWebhooks"
            :key="'webhook-' + webhook.id"
          >
            <b-td>
              {{ webhook.action | typeFormat }}.{{ webhook.object }}
            </b-td>
            <b-td>{{ webhook.url }}</b-td>
            <b-td>
              {{ webhook.created_at | date('DD MMM , YYYY') }}
              {{ webhook.created_at | time('HH:mm') }}<br />
              {{ webhook.created_by.data.full_name }}
            </b-td>
            <b-td>
              {{ webhook.last_delivery_time | date('DD MMM , YYYY') }}
              {{ webhook.last_delivery_time | time('HH:mm') }}<br />
              <span
                v-if="webhook.last_http_status == 200"
                class="status-success"
              >
                {{ webhook.last_http_status }}
              </span>
              <span v-else class="status-error">
                {{ webhook.last_http_status }}
              </span>
            </b-td>
            <b-td>
              <div
                class="trash"
                title="Supprimer"
                @click="clickdeleteWebhook(webhook)"
              >
                <font-awesome-icon class="icon-trash" icon="trash" />
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <!-- update webhook -->
    <b-modal
      no-close-on-backdrop
      id="addWebhook"
      ref="addWebhook"
      title="Créer un nouveau webhook"
      :hide-footer="true"
      @show="resetModal"
      @hidden="resetModal"
    >
      <form @submit.prevent="ajouterWebhook">
        <b-row>
          <b-col cols="6">
            <b-form-group
              label="Action de l’évènement"
              label-for="action-input"
            >
              <b-form-select
                id="action-input"
                v-model="webhookToAdd.action"
                required
                ><option value="*">*</option>
                <option value="added">Ajouter</option>
                <option value="updated">Modifier</option>
                <option value="deleted">Supprimer</option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Objet de l’évènement" label-for="objet-input">
              <b-form-select
                id="objet-input"
                v-model="webhookToAdd.object"
                required
              >
                <option value="*">*</option>
                <option value="projets">Projets</option>
                <option value="contacts">Contacts</option>
                <!-- <option value="activities">Activités</option>
                <option value="pcomments">Commentaire</option> -->
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group label="URL du point de destination" label-for="url-input">
          <b-form-input
            id="url-input"
            v-model="webhookToAdd.url"
            required
          ></b-form-input>
        </b-form-group>
        <div class="message">
          <div v-if="getWebhookLoading" class="three-dots-loading loading">
            Chargement en cours
          </div>
          <div v-if="getWebhookError" class="error">
            <ul v-if="Array.isArray(getWebhookError)">
              <li v-for="(e, index) in getWebhookError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getWebhookError }}</span>
          </div>
        </div>
        <div class="form-actions">
          <b-button
            @click="hideModal('addWebhook')"
            variant="outline-secondary"
          >
            Annuler
          </b-button>
          <b-button variant="success" type="submit">
            Enregistrer
          </b-button>
        </div>
      </form>
    </b-modal>
    <!-- delete webhook -->
    <b-modal
      scrollable
      no-close-on-backdrop
      ref="ModalDeleteWebhook"
      id="ModalDeleteWebhook"
      title="Supprimer un webhook"
      :hide-footer="true"
    >
      <p>
        Êtes-vous certain de vouloir supprimer le webhook
        <strong class="name_delete" v-if="webhookToDelete">
          {{ webhookToDelete.action | typeFormat }}.{{ webhookToDelete.object }}
        </strong>
        ?
      </p>
      <div class="message">
        <div v-if="getWebhookLoading" class="three-dots-loading loading">
          Chargement en cours
        </div>
        <div v-if="getWebhookError" class="error">
          <ul v-if="Array.isArray(getWebhookError)">
            <li v-for="(e, index) in getWebhookError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getWebhookError }}</span>
        </div>
      </div>
      <div class="form-actions">
        <b-button
          @click="hideModal('ModalDeleteWebhook')"
          variant="outline-secondary"
        >
          Annuler
        </b-button>
        <b-button @click="supprimerWebhook" variant="success">
          Enregistrer
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
export default {
  name: 'Webhook',
  data() {
    return {
      webhookToAdd: {
        action: null,
        object: null,
        url: null
      },
      webhookToDelete: null
    }
  },
  methods: {
    ...mapActions(['addWebhook', 'deleteWebhook', 'fetchListWebhook']),
    hideModal(ref) {
      this.$refs[ref].hide()
      this.resetModal()
    },
    resetModal() {
      this.webhookToAdd = {
        action: null,
        object: null,
        url: null
      }
      this.webhookToDelete = null
    },
    clickdeleteWebhook(webhook) {
      this.webhookToDelete = webhook
      this.$refs['ModalDeleteWebhook'].show()
    },
    async ajouterWebhook() {
      await this.addWebhook(this.webhookToAdd)
      this.hideModal('addWebhook')
    },
    async supprimerWebhook() {
      await this.deleteWebhook(this.webhookToDelete)
      this.hideModal('ModalDeleteWebhook')
    }
  },
  computed: {
    ...mapGetters(['getWebhooks', 'getWebhookLoading', 'getWebhookError'])
  },
  filters: {
    typeFormat: value => {
      switch (value) {
        case 'added':
          return 'Ajouter'
        case 'updated':
          return 'Modifier'
        case 'deleted':
          return 'Supprimer'
        case '*':
          return '*'
        default:
          return ''
      }
    },
    date: function(value, format = 'DD/MM/YYYY') {
      if (!value) return ''
      return moment(value).format(format)
    },
    time: function(value, format = 'HH:mm:ss') {
      if (!value) return ''
      return moment(value).format(format)
    }
  },
  async mounted() {
    await this.fetchListWebhook()
  }
}
</script>
<style lang="scss" scoped>
.page-content-webhook {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .webhook {
    .webhook-table {
      border: 1px solid #dee2e6;
      .header-tr {
        th {
          border: 1px solid #dee2e6;
          font-size: smaller;
        }
      }
      .sub-header-tr {
        font-size: 13px;
        td {
          border: 1px solid #dee2e6;
          vertical-align: middle;
          .status-success,
          .status-error {
            font-weight: 600;
            &.status-success {
              color: #4caf50;
            }
            &.status-error {
              color: #ff5722;
            }
          }
          .trash {
            text-align: center;
            cursor: pointer;
            .icon-trash {
              height: 2em;
            }
          }
        }
      }
    }
  }
}
</style>
